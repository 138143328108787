import { toast } from '@landler/tw-component-library';
import { Stack } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { KycEnum } from '@/api/rest/resources/types/user';
import { usePatchUserMe } from '@/api/rest/resources/user';
import { ControlledTextInput } from '@/components/Form/ControlledTextInput';
import { useUser, useUserContext } from '@/lib/auth';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';

import { EditContainerLayout, EditContainerLayoutBox } from '../../components';
import { AccountKycStatus } from '../../components/kyc/status/status';
import { AccountKycVerificationBox } from '../../components/kyc/verification-box/VerificationBox';

interface Inputs {
  firstname: string;
  lastname: string;
}

const formFields: (keyof Inputs)[] = ['firstname', 'lastname'];

export const AccountEditNamePage = () => {
  const { t } = useTranslation();
  const { firstname, lastname, membership, kyc } = useUser();
  const { updateUserPartially } = useUserContext();
  const isUserLandSteward = membership.type === MembershipWithOrganizationTypeEnum.land_steward;
  const isKycSectionShown = isUserLandSteward && kyc !== KycEnum.success;
  const isInputsDisabled = [KycEnum.in_progress, KycEnum.success].includes(kyc);

  const { control, formState, handleSubmit, setError, reset, watch } = useForm<Inputs>({
    defaultValues: {
      firstname,
      lastname,
    },
  });

  const firstnameInputValue = watch('firstname');
  const lastnameInputValue = watch('lastname');

  const isSaveButtonDisabled =
    isInputsDisabled || firstnameInputValue === '' || lastnameInputValue === '' || !formState.isDirty;

  const { isPending, mutate } = usePatchUserMe();

  const handleServerError = useRestApiServerErrorHandling(setError, formFields);

  const handleSave: SubmitHandler<Inputs> = (data) => {
    if (!formState.isDirty) {
      return;
    }

    mutate(
      { bodyData: { firstname: data.firstname, lastname: data.lastname } },
      {
        onSuccess: async (updatedUser) => {
          updateUserPartially(updatedUser);
          reset({ firstname: updatedUser.firstname, lastname: updatedUser.lastname });

          toast({
            title: t('shared.account.editName.success'),
            type: 'success',
          });
        },
        onError: handleServerError,
      },
    );
  };

  return (
    <EditContainerLayout
      isSubmitting={isPending}
      onSave={handleSubmit(handleSave)}
      isSaveButtonDisabled={isSaveButtonDisabled}
      title={t('shared.account.editName.title')}
      dataCy='edit-name'
    >
      <div className='flex flex-col-reverse gap-6 sm:flex-col'>
        {isKycSectionShown && <AccountKycVerificationBox status={kyc} />}
        <EditContainerLayoutBox>
          <Stack spacing={4} maxWidth='480px'>
            <ControlledTextInput
              id='firstname'
              name='firstname'
              label={t('shared.account.editName.labels.firstname')}
              control={control}
              rules={{
                required: t('global.ui.form.input.required'),
              }}
              disabled={isInputsDisabled}
              data-cy='edit-name-firstname-input'
            />
            <div className='flex flex-col gap-2'>
              <ControlledTextInput
                id='lastname'
                name='lastname'
                label={t('shared.account.editName.labels.lastname')}
                control={control}
                rules={{
                  required: t('global.ui.form.input.required'),
                }}
                disabled={isInputsDisabled}
                data-cy='edit-name-lastname-input'
              />
              {isUserLandSteward && <AccountKycStatus status={kyc} />}
            </div>
          </Stack>
        </EditContainerLayoutBox>
      </div>
    </EditContainerLayout>
  );
};
