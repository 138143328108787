import { ButtonLink } from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FullScreenError } from '@/components';
import { useContactFormLink } from '@/hooks/useContactFormLink';
import { paths } from '@/routing';

import { ErrorPageState } from '../../hooks/useLandStewardCompleteAccountPage';

interface LandStewardCompleteAccountErrorProps {
  pageState: keyof typeof ErrorPageState;
}

export const LandStewardCompleteAccountError: React.FC<LandStewardCompleteAccountErrorProps> = ({ pageState }) => {
  const { t } = useTranslation();
  const contactLink = useContactFormLink();

  if (pageState === ErrorPageState.EXPIRED) {
    return <LinkExpired />;
  }

  return (
    <div>
      <FullScreenError
        title={t('landSteward.completeAccount.error.title')}
        body={t('landSteward.completeAccount.error.message')}
        actions={
          <ButtonLink target='_blank' to={contactLink} variant='outline'>
            {t('notFound.labels.contactSupportButton')}
          </ButtonLink>
        }
      />
    </div>
  );
};

const LinkExpired = () => {
  const { t } = useTranslation();
  const contactLink = useContactFormLink();

  return (
    <div>
      <FullScreenError
        title={
          <span className='typography-display3 mb-8 text-center text-info'>
            {t('landSteward.completeAccount.error.expired.title')}
          </span>
        }
        body={
          <span className='text-primary typography-body1 whitespace-pre-wrap'>
            {t('landSteward.completeAccount.error.expired.info')}
          </span>
        }
        actions={
          <>
            <ButtonLink variant='outline' to={contactLink} target='_blank' className='mr-0 md:mr-auto'>
              {t('landSteward.completeAccount.error.expired.contactSupport')}
            </ButtonLink>
            <ButtonLink size='medium' to={paths.login}>
              {t('landSteward.completeAccount.error.expired.continueToLogin')}
            </ButtonLink>
          </>
        }
      />
    </div>
  );
};
