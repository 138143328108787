import { Text } from '@landler/component-library';
import { ButtonLink, RiErrorWarningLine } from '@landler/tw-component-library';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingPageHelperLink } from '@/layout/OnboardingPage';
import { paths } from '@/routing';

import { NewPasswordPageContent } from '../page-content/PageContent';

interface NewPasswordTokenInvalidProps {
  error: string;
}

export const NewPasswordTokenInvalid: React.FC<NewPasswordTokenInvalidProps> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <>
      <NewPasswordPageContent headlineText={t('passwordResetTokenInvalid.title')}>
        <Stack direction='row' spacing={1.5} alignItems='center'>
          <RiErrorWarningLine size={22} className='text-error' />
          <Text variant='body2' color='error.dark'>
            {error}
          </Text>
        </Stack>
        <ButtonLink to={paths.resetPassword} size='medium' className='self-end'>
          {t('passwordResetTokenInvalid.retry')}
        </ButtonLink>
      </NewPasswordPageContent>
      <OnboardingPageHelperLink i18nKey='passwordResetTokenInvalid.back' to={paths.login} />
    </>
  );
};
