import backgroundDesktop from '@assets/images/trim.svg';
import backgroundMobile from '@assets/images/trim-mobile.svg';
import { Alert, Button } from '@landler/component-library';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Footer } from '@/components';
import { ControlledTextInput } from '@/components/Form/ControlledTextInput';
import { SELF_SIGNUP_ENABLED } from '@/config/constants';
import { useContactFormLink } from '@/hooks/useContactFormLink';
import { useScreenSize } from '@/hooks/useScreenSize';
import { OnboardingPageHeading, OnboardingPageHelperLink, OnboardingPagePaper } from '@/layout/OnboardingPage';
import { OnboardingNavbar } from '@/layout/OnboardingPage/OnboardingNavbar/OnboardingNavbar';
import { useAuth } from '@/lib/auth';
import { paths } from '@/routing';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';
import { validateEmail, validatePassword } from '@/utils/validators';

import { HelperTextAwarePasswordInput } from './components/HelperTextAwarePasswordInput';

interface Inputs {
  email: string;
  password: string;
}

const defaultValues: Inputs = {
  email: '',
  password: '',
};

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const contactFormLink = useContactFormLink();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { control, handleSubmit, setError } = useForm<Inputs>({
    defaultValues,
  });
  const { signIn, isLoading } = useAuth();
  const handleServerError = useRestApiServerErrorHandling(setError);
  const [signInError, setSignInError] = useState('');

  const deepLink = state?.loginDeeplink;

  const handleSignIn: SubmitHandler<Inputs> = async (data) => {
    try {
      setSignInError('');
      await signIn(data);
      navigate(deepLink || paths.root, { replace: true });
    } catch (e) {
      const { serverErrors } = handleServerError(e);
      if (serverErrors[0]) {
        setSignInError(serverErrors[0]);
        return;
      }

      throw e;
    }
  };

  /**
   * When trying to access a protected page while logged out, we show a slightly
   * different version of the login page explaining that you need to login first
   * before proceeding.
   * The exception here is root '/', where we always show the regular login UI.
   */
  const showProtectedPageVariant = Boolean(deepLink) && deepLink !== '/';
  const requiredMessage = t('global.ui.form.input.required');
  return (
    <Stack
      className='min-h-screen items-center bg-cover'
      data-cy='bg-cover'
      style={{ backgroundImage: `url("${isSmallScreen ? backgroundMobile : backgroundDesktop}")` }}
    >
      <OnboardingNavbar />

      <Stack
        direction='column'
        justifyContent='center'
        sx={{ maxWidth: showProtectedPageVariant ? 664 : 526, width: 'calc(100% - 40px)', flex: 1 }}
      >
        {!showProtectedPageVariant && <OnboardingPageHeading dataCy='login' headlineText={t('login.title')} />}
        <OnboardingPagePaper data-cy='login-paper'>
          <Stack component='form' spacing={3} onSubmit={handleSubmit(handleSignIn)}>
            {showProtectedPageVariant && (
              <>
                <h1 className='typography-display3 text-center text-primary-100' data-cy='login-title'>
                  {t('login.loginRequiredTitle')}
                </h1>
                <span className='typography-body1 text-center'>{t('login.loginRequiredDescription')}</span>
              </>
            )}
            <ControlledTextInput
              id='email'
              name='email'
              type='email'
              control={control}
              label={t('login.email.label')}
              placeholder={t('login.email.placeholder')}
              data-cy='email-input'
              rules={{
                required: requiredMessage,
                validate: (value) => validateEmail(value) || t('global.ui.form.input.emailInvalid'),
              }}
            />
            <ControlledTextInput
              id='password'
              label={t('login.password.label')}
              name='password'
              type='password'
              control={control}
              inputProps={{
                autoComplete: 'password',
              }}
              component={HelperTextAwarePasswordInput}
              data-cy='password-input'
              rules={{
                required: requiredMessage,
                validate: (password) => validatePassword(password) || t('global.ui.form.input.passwordMinLength'),
              }}
            />
            {!!signInError && (
              <Alert variant='filled' severity='error' data-testid='signInError-Alert' data-cy='login-alert'>
                {signInError}
              </Alert>
            )}
            <Button
              type='submit'
              size='large'
              isLoading={isLoading}
              sx={{ alignSelf: 'end' }}
              data-cy='submit-button'
            >
              {t('login.submit')}
            </Button>
          </Stack>
        </OnboardingPagePaper>
        {SELF_SIGNUP_ENABLED ? (
          <OnboardingPageHelperLink i18nKey='login.signUpLink' to={paths.landSteward.signUp} data-cy='sign-up' />
        ) : (
          <OnboardingPageHelperLink
            i18nKey='login.contactUsLink'
            to={contactFormLink}
            target='_blank'
            data-cy='sign-up'
          />
        )}
      </Stack>

      {!isSmallScreen && (
        <div className='full-bleed-x'>
          <Footer />
        </div>
      )}
    </Stack>
  );
};
