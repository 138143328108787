import { Text } from '@landler/component-library';
import { ButtonLink } from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@/lib/auth';
import { paths } from '@/routing';

import { ActivateAccountPageContent } from '../page-content/PageContent';

export const ActivateAccountSuccess: React.FC = () => {
  const { t } = useTranslation();

  const { isSignedIn } = useAuth();

  return (
    <ActivateAccountPageContent headlineText={t('activateAccount.success.title')} dataCy='activate-account'>
      <Text variant='body1' data-cy='activate-account-text'>
        {t('activateAccount.success.text')}
      </Text>
      <ButtonLink
        size='medium'
        className='self-end'
        data-cy='activate-account-button'
        to={isSignedIn ? paths.landSteward.projects : paths.login}
      >
        {isSignedIn ? t('activateAccount.success.continueToProjects') : t('activateAccount.success.continueToLogin')}
      </ButtonLink>
    </ActivateAccountPageContent>
  );
};
