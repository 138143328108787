import { Text } from '@landler/component-library';
import { ButtonLink } from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { paths } from '@/routing';

import { NewPasswordPageContent } from '../page-content/PageContent';

export const NewPasswordSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NewPasswordPageContent dataCy='password-reset-success' headlineText={t('passwordResetSuccess.title')}>
      <Text variant='body1'>{t('passwordResetSuccess.subtitle')}</Text>
      <ButtonLink to={paths.landSteward.projects} size='medium' className='self-end'>
        {t('passwordResetSuccess.continue')}
      </ButtonLink>
    </NewPasswordPageContent>
  );
};
