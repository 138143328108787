import {
  cn,
  InfoPopoverProps,
  RiArrowRightDownLine,
  RiArrowRightLine,
  RiArrowRightUpLine,
  RiCoinLine,
  RiLock2Line,
} from '@landler/tw-component-library';
import React, { HTMLAttributes, useMemo } from 'react';

import { NCABalance, NCAReportBalanceValueType, SIGNED_NCA_BALANCE_TYPES } from '@/api/rest/resources/types/ncaBalance';
import { useBalance } from '@/hooks/useBalance';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

import {
  Capsule,
  CapsuleContent,
  CapsuleContentProps,
  CapsuleIcon,
  CapsuleIconProps,
  CapsuleLabel,
} from '../../../../../../../../../../../components/Capsule/Capsule';

export type NCABalanceCapsuleProps = HTMLAttributes<HTMLDivElement> & {
  capsuleIconProps?: CapsuleIconProps;
  label: React.ReactNode;
  balance?: NCABalance;
  infoPopoverProps?: InfoPopoverProps | null;
  trend?: CapsuleContentProps['trend'];
};

// TODO: MVP-3284 unify with NCFactCapsule
export const NCABalanceCapsule: React.FC<NCABalanceCapsuleProps> = ({
  capsuleIconProps,
  label,
  balance,
  trend,
  infoPopoverProps,
  className,
}) => {
  const isSmallScreen = useScreenSize() === 'small';

  const infoPopoverPropsDelegated = useMemo(() => {
    if (isSmallScreen) return null;

    // TODO: MVP-3284 Update Capsule's styling to use text-xs instead of passing it as an override here
    return {
      ...infoPopoverProps,
      popoverTriggerProps: {
        ...infoPopoverProps?.popoverTriggerProps,
        className: cn('text-xs', infoPopoverProps?.popoverTriggerProps?.className),
      },
    } as InfoPopoverProps;
  }, [isSmallScreen, infoPopoverProps]);

  const icon = useNCBalanceChangeIcon(balance);
  const variant = getNCBalanceChangeIconVariant(balance);
  return (
    <Capsule
      label={<CapsuleLabel infoPopoverProps={infoPopoverPropsDelegated}>{label}</CapsuleLabel>}
      content={<ValueFromBalance balance={balance} trend={trend} className={className} />}
      thumbnail={<CapsuleIcon icon={capsuleIconProps?.icon ?? icon} variant={capsuleIconProps?.variant ?? variant} />}
    />
  );
};

type ValueFromBalanceProps = HTMLAttributes<HTMLDivElement> & {
  balance?: NCABalance;
  trend?: CapsuleContentProps['trend'];
};

const ValueFromBalance: React.FC<ValueFromBalanceProps> = ({ balance, trend, className }) => {
  const { value, display } = useBalance(balance);

  if (value == null) {
    return <CapsuleContent className={cn('text-text-disabled', className)}>-</CapsuleContent>;
  }

  return (
    <CapsuleContent className={className} trend={trend}>
      {display}
    </CapsuleContent>
  );
};

/**
 * value              | change  |  signed fact
 * latest - null          +/-           n         = coin
 * latest - exists        +/-           n         = coin
 *
 * opening - null          +/-          n         = coin
 * opening - exists        +/-          n         = coin
 * opening - null/locked   +/-          n         = coin
 * opening - exists/locked +/-          n         = lock
 *
 * delta - exists          +            y         = right up arrow
 * delta - exists          -            y         = right down arrow
 * delta - null           +/-           n/a       = right straight arrow
 *
 * @param balance
 * @returns Icon
 */
export const useNCBalanceChangeIcon = (balance: NCABalance | undefined) => {
  const projectDetail = useProjectDetailById().data;
  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');
  if (!balance) {
    return <RiCoinLine />;
  }

  if (!balance.value) {
    if ([...SIGNED_NCA_BALANCE_TYPES].includes(balance.type)) return <RiArrowRightLine />;
    return <RiCoinLine />;
  }

  if ([NCAReportBalanceValueType.opening_value].includes(balance.type)) {
    if (!isProjectEditable) return <RiLock2Line />;
    return <RiCoinLine />;
  }

  if ([NCAReportBalanceValueType.latest_value].includes(balance.type)) {
    return <RiCoinLine />;
  }

  if ([...SIGNED_NCA_BALANCE_TYPES].includes(balance.type)) {
    if ((balance.value as number) === 0) return <RiArrowRightLine />;
    if ((balance.value as number) > 0) return <RiArrowRightUpLine />;
    return <RiArrowRightDownLine />;
  }

  return <RiCoinLine />;
};

/**
 * @param balance
 * @returns variant?: 'default' | 'contained' | 'disabled';
 */
export const getNCBalanceChangeIconVariant = (balance: NCABalance | undefined) => {
  if (!balance || !balance.value) return 'disabled';
  return 'default';
};
